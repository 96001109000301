import * as React from "react";
import Layout2 from "../../../components/layout2";

// styles

// data

// markup
const TermsConditionPage = () => {
  return (
    <Layout2
      title="ご利用条件 | CO Record &amp; journal"
      policyStyle={true}
      showHeader={true}
      isTop={false}
    >
      <main id="id-main" className="policy-body">
        <section>
          <div className="contents">
            <div className="text-box">
              <br />
              <br />
              <br />

              <h3 id="ウェブサイトご利用条件">ウェブサイトご利用条件</h3>
<p>「dive-co.net」又は「dive-co.jp」（以下、「当ウェブサイト」といいます）は、帝人株式会社（以下、「当社」といいます）が運営しています。</p>
<p>次のウェブサイトご利用条件をお読みいただき、これらのご利用条件に同意された上で、当ウェブサイトをご利用ください。なお、当ウェブサイトは、当社のグループ会社が運営する他のウェブサイトもリンクされています。それらのウェブサイトをご利用される場合には、それらのウェブサイトに掲載されている利用条件にも同意いただく必要がありますのでご了承ください。</p>
<h4 id="著作権等">著作権等</h4>
<p>当ウェブサイトに掲載されている情報（資料、文書、画像、写真、イラスト、動画、映像、音声、音楽、ソフトウェア、意匠、電子メールで発信・配信される情報を含みますがこれに限りません）に関する著作権等の権利は、当社および当社のグループ会社（以下、当社と当社のグループ会社を併せて「帝人グループ」といいます）又は当社に当該権利の利用を許諾した権利者に帰属しています。著作権法その他の法律によって明示的に認められる範囲を超えてこれらの情報を使用すること（複製、改変、アップロード、掲示、転載、頒布等を含みます）は、当社の文書による事前の許諾がない限り禁止されます。</p>
<h4 id="商標">商標</h4>
<p>当ウェブサイトに掲載されている製品・サービス等の名称は、帝人グループ若しくは当社に当該権利の利用を許諾した権利者の商標又は登録商標です。当ウェブサイトに掲載されている商標を当社に無断で使用することは禁止します。</p>
<h4 id="リンクの取り扱い">リンクの取り扱い</h4>
<p>当ウェブサイトへのリンクに関しては、次の注意事項に従ってください。<br />
（1）帝人グループの商標権その他の知的財産権を侵害する態様でのリンクはお断りします。<br />
（2）帝人グループの社会的信用を損ない若しくはその恐れが生じる態様によるリンク又は帝人グループの業務を妨害するような態様によるリンクはお断りします。また、違法な内容、公序良俗に反する内容、反社会的な内容が含まれる他のサイトからのリンクもお断りします。<br />
（3）他のウェブサイトから当ウェブサイトにリンクを張ったこと又はそれに関連したことにより生じたいかなる損害についても、当社は責任を負わないものとします。<br />
当ウェブサイトから第三者が運営する他のウェブサイトにリンクする場合でも、リンクされた他のサイトは当該第三者の責任において管理・運営されています。これら他のウェブサイトの内容及びそれらを利用したことに関して生じたいかなる損害についても、当社は責任を負わないものとします。</p>
<h4 id="電子メールメールフォーム等の取り扱い">電子メール、メールフォーム等の取り扱い</h4>
<p>当社は、当ウェブサイトへの電子メール、メールフォーム等による通信に対して、返答する義務を負いません。また、返答によって生じたいかなる損害についても、当社は責任を負わないものとします。</p>
<h4 id="アイディアのご提案">アイディアのご提案</h4>
<p>当社はお客様からお寄せいただく商品、技術、デザイン等のアイディアに関するご提案については、受け取りをご遠慮させていただきます。<br />
上記の当社の方針にもかかわらず、万一お客様からご提案をいただいた場合には、ご提案いただいたアイディアについてお客様が下記の点に同意したものとみなしますので、お客様がこれらのアイディアを当社に提供する場合には、下記の点に同意の上、ご提案ください。<br />
（1）お客様からのご提案について、当社が何らの守秘義務も負わないこと。<br />
（2）お客様からのご提案と同一又は類似の商品・サービスが帝人グループから発表又は販売された場合でも、帝人グループはお客様に対して対価の支払義務、その他何らの義務も負わないこと。<br />
（3）ご提案について、お客様が帝人グループ及び帝人グループの取引先に対して著作者人格権を含む一切の権利を行使しないこと。</p>
<h4 id="免責事項等">免責事項等</h4>
<p>当社は、当ウェブサイト及び当ウェブサイトに掲載する情報について、細心の注意を払っていますが、当ウェブサイトに掲載する情報が正確なものであるか、確実なものであるか、ご使用になるお客様にとって有用なものであるか、安全なものであるか（コンピューターウィルス等の有害物が含まれていないこと等）について何らの保証もするものではありません。当ウェブサイト及び当ウェブサイトに掲載された情報を利用したこと、又は利用できなかったことによって生じるいかなる損害についても、当社は責任を負いません。<br />
当社は、予告なしに当ウェブサイト上の情報やURLを変更、削除、又は当ウェブサイトの運用を中断、中止することがありますので、ご利用の際は最新の内容をご確認いただきますようお願いします。当社は、これらによって生じるいかなる損害についても責任を負いません。
また、当ウェブサイトの情報は、当社株式の購入や売却を勧誘するものではありません。投資に関するご決定は、お客様ご自身のご判断で行われるようお願いします。</p>
<h4 id="準拠法管轄裁判所">準拠法、管轄裁判所</h4>
<p>当ウェブサイトのご利用並びにこの「ウェブサイトご利用条件」の解釈及び適用は、日本国法に準拠するものとします。<br />
また、当ウェブサイトの利用に関わる全ての紛争については、訴額に応じて、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
<h4 id="利用する技術">利用する技術</h4>
<p>当ウェブサイトは原則として次の技術を利用して作成されています。</p>
<ul>
<li>HTML5</li>
<li>CSS3</li>
<li>JavaScript</li>
<li>上記の技術をサポートしたブラウザであれば当ウェブサイトを閲覧することができます。</li>
</ul>
<p>2023年9月に以下のブラウザで閲覧できることを確認していますがOSやブラウザなどの利用環境によって表示上の差異が生じることがあります。</p>
<ul>
<li>Windows 10</li>
<li>Windows 11</li>
<li>Google Chrome 最新版</li>
<li>Microsoft Edge 最新版</li>
<li>Mozilla Firefox 最新版</li>
<li>Mac OS</li>
<li>Safari 最新版</li>
</ul>
<p>※OS、ブラウザのバージョンアップ等に伴う対応のために一時的に、全部又は一部の機能の利用が制限される場合があります。</p>
<p>※何らかの理由により、当ウェブサイトが利用いただけない場合があります。その際、当ウェブサイトの復旧にお時間をいただくことがございます。</p>
            </div>
          </div>
        </section>
      </main>
    </Layout2>
  );
};

export default TermsConditionPage;
